import { v4 as uuid } from 'uuid';

import { TileParkPositions } from 'components-typescript-react';
import React from 'react';
import { createRoot } from 'react-dom/client';

import {
  AppPropManagerOptions,
  AppPropManagerProps,
  AppProps,
  Command,
  CommandTypes,
  LenderName,
  ProductNames,
  ProductVersion,
} from '@app-types';
import App from '@components/App';
import CheckoutAuthCertificate from '@utils/CheckoutAuthCertificate';
import { CheckoutAuthCertificateLens, Offers } from '@utils/CheckoutAuthCertificate/types';
import { DekoWalletCheckoutError } from '@utils/Errors/types';
import getLenderFromProduct from '@utils/getLenderFromProduct';
import InitCertificate from '@utils/InitCertificate';
import { InitCertificateLens } from '@utils/InitCertificate/types';
import MessageCommands from '@utils/MessageListener/types';
import sendMessage from '@utils/sendMessage';

const instanceId = `_${uuid()}`;

class AppPropManager implements AppPropManagerProps {
  static activeLender: LenderName;

  static isCheckoutToken: boolean = false;

  static isShownWIQButton: boolean = true;

  static productAmount?: number;

  public authToken: string = '';

  private static _offers: Offers[] = [];

  private static _productVersion: ProductVersion;

  private _firstAvailableProduct: ProductNames;

  private _authCertificate?: CheckoutAuthCertificateLens;

  private _initCertificate?: InitCertificateLens;

  private _isVisible: boolean;

  private _promotionalValue?: number = undefined;

  private _initToken: string = '';

  private _error?: DekoWalletCheckoutError = undefined;

  private _isOpen: boolean = false;

  private readonly rootSelector: string;

  private readonly tileParkPosition: TileParkPositions;

  private readonly onApplicationEvent: AppPropManagerOptions['onApplicationEvent'];

  private readonly onClose: AppProps['onClose'];

  private wasVisible: boolean = false;

  private readonly walletElement: HTMLElement;

  private readonly _isStandaloneUI: boolean;

  private readonly root: any;

  constructor({
    isVisible,
    tileParkPosition,
    onClose,
    onApplicationEvent,
    isStandaloneUI = false,
  }: AppPropManagerOptions) {
    this.isVisible = isVisible;
    this.tileParkPosition = tileParkPosition;
    this.onClose = onClose;
    this.onApplicationEvent = onApplicationEvent;
    this.rootSelector = `.${instanceId}`;

    this._isStandaloneUI = isStandaloneUI;

    this.walletElement = document.createElement('div');
    this.walletElement.className = instanceId;
    this.walletElement.style.width = this._isStandaloneUI ? '100%' : 'auto';

    window.document.body.appendChild(this.walletElement);
    this.root = createRoot(this.walletElement);
  }

  public readonly onEmit = (command: Command): void => {
    switch (command.type) {
      case CommandTypes.close:
        this._isOpen = false;
        this._promotionalValue = undefined;
        setTimeout(() => {
          this._isVisible = this.wasVisible;
        }, 300);
        this.renderWallet();
        this.onClose?.();
        break;
      case CommandTypes.soft_close:
        this._isOpen = false;
        this._promotionalValue = undefined;
        setTimeout(() => {
          this._isVisible = this.wasVisible;
        }, 300);
        this.renderWallet();
        break;
      case CommandTypes.open:
        this.wasVisible = this._isVisible;
        if (!this._isVisible) {
          this._isVisible = true;
        }
        this._isOpen = true;
        this.renderWallet();
        break;
      case CommandTypes.dismiss:
        this.isVisible = false;
        break;
      case CommandTypes.engage:
        this.isVisible = true;
        break;
      case CommandTypes.clearPromotional:
        this._promotionalValue = undefined;
        AppPropManager.productAmount = undefined;
        this.renderWallet();
        break;
      default:
        console.warn('unknown command', command.type);
    }
  };

  private readonly renderWallet = (): void => {
    if (this.isValidToken) {
      this.root.render(<App {...this.getComponentProps()} />);
    }
  };

  private get isValidToken(): boolean {
    return this.authToken.length > 0 || this._initToken?.length > 0;
  }

  private readonly getComponentProps = (): AppProps => ({
    onClose: this.onClose,
    onEmit: this.onEmit,
    onApplicationEvent: this.onApplicationEvent,
    promotionalValue: this._promotionalValue,
    tileParkPosition: this.tileParkPosition,
    isVisible: this._isVisible,
    authToken: this.authToken,
    authCertificate: this.authCertificate,
    initToken: this._initToken,
    initCertificate: this.initCertificate,
    firstAvailableProduct: this._firstAvailableProduct,
    rootSelector: this.rootSelector,
    isOpen: this._isOpen,
    error: this._error,
    isStandaloneUI: this._isStandaloneUI,
  });

  private set isVisible(isVisible: boolean) {
    this._isVisible = isVisible;
    this.renderWallet();
  }

  private set firstAvailableProduct(availableProductName: ProductNames) {
    this._firstAvailableProduct = availableProductName;
    AppPropManager.activeLender = getLenderFromProduct(this._firstAvailableProduct);
  }

  public set promotionalValue(value: number) {
    this._error = undefined;
    this._promotionalValue = value;
    AppPropManager.productAmount = this._promotionalValue;
  }

  public setAuthToken(token: string, isCheckoutToken = false) {
    this.authToken = token;
    this._authCertificate = CheckoutAuthCertificate(token);
    this.firstAvailableProduct = this._authCertificate?.availableProductName;
    this._error = undefined;
    this._promotionalValue = undefined;
    AppPropManager.isCheckoutToken = isCheckoutToken;

    if (isCheckoutToken) {
      this.onEmit({ type: CommandTypes.open });
    } else {
      this.isOpen = isCheckoutToken;
    }
  }

  public get authCertificate(): CheckoutAuthCertificateLens {
    return this._authCertificate;
  }

  public set initToken(token: string) {
    this._initToken = token;
    this._initCertificate = InitCertificate(token);
    this.firstAvailableProduct = this._initCertificate.availableProductName;

    AppPropManager.productVersion = this._initCertificate.env?.productVersion as ProductVersion;
    AppPropManager.setOffers(this._initCertificate.offers);

    if (this.initCertificate.authToken) {
      this.setAuthToken(this.initCertificate.authToken);
    } else {
      this.renderWallet();
    }
  }

  public get initToken(): string {
    return this._initToken;
  }

  public get initCertificate(): InitCertificateLens {
    return this._initCertificate;
  }

  public set error(error: DekoWalletCheckoutError) {
    this._error = error;
    this.renderWallet();
  }

  public set isOpen(isOpen: boolean) {
    this._isOpen = isOpen;
    this.renderWallet();
  }

  public static set productVersion(version: ProductVersion) {
    this._productVersion = version;
  }

  public static get isProductVersionV2(): boolean {
    return this._productVersion === ProductVersion.V2;
  }

  public static get offers(): Offers[] {
    return this._offers;
  }

  public static set offers(offers: Offers[]) {
    this._offers = offers;
  }

  static setOffers(offers: Offers[]): void {
    AppPropManager._offers = offers;
    AppPropManager.handleFinanceInfoUpdate();
  }

  static handleFinanceInfoUpdate(): void {
    sendMessage({ message: MessageCommands.PREQUALIFICATION_ACCEPT });
  }

  static handlePartnerChange(partner: LenderName): void {
    sendMessage({ message: MessageCommands.PREQUALIFICATION_PARTNER, partner });
  }
}

export default AppPropManager;
