import { Buffer } from 'buffer';

import type { EnvironmentVariableContextInterface } from 'components-typescript-react/dist/components/Workflow/atoms/ENVCONTEXT/ENVCONTEXT';

import { ProductNames, SchemaObject } from '@app-types';
import { encodeBase64 } from '@components/WorkflowStep/productInfo';
import { Commands } from '@components/WorkflowStep/utils/constants';
import AppPropManager from '@utils/AppPropManager';
import calculateInstalment from '@utils/calculateInstalment';
import { Currency, Offers } from '@utils/CheckoutAuthCertificate/types';
import handleCookieUIs from '@utils/Cookies';
import { CookieType, LocalStorageType } from '@utils/eligibilityBeaconPersistence/types';
import getValidOffers from '@utils/getValidOffers';
import MessageCommands from '@utils/MessageListener/types';
import Cookies from 'js-cookie';

export type IFrameRemoveEventListener = () => void;
type OnEventIFramePayload = { type: Commands; data: string };

export type OnLoadIFrameParams = {
  iFrame: HTMLIFrameElement;
  schema: SchemaObject;
  environmentVariables: EnvironmentVariableContextInterface;
  originUrl: string;
};

const createInstalments = (productAmount: number): Offers[] =>
  getValidOffers(AppPropManager.offers, productAmount * 100).map((offer) => {
    const { interestToPay, monthlyInstalment, total } = calculateInstalment(productAmount, offer);

    return {
      ...offer,
      illustration: {
        monthlyPayment: { value: monthlyInstalment, currencyCode: Currency.GBP },
        totalRepayable: { value: total.toFixed(2), currencyCode: Currency.GBP },
        interestToPay: { value: interestToPay.toFixed(2), currencyCode: Currency.GBP },
      },
    };
  });

const getPromotionalPageNameFromSchema = (schema: SchemaObject): { _page: string; _pageId: string } => {
  switch (schema._page) {
    case 'FlexPaySplashNoProduct':
      return { _page: 'FlexPayEligibilitySplashWithProduct', _pageId: 'A04' };
    case 'NewPaySplashPage':
      return { _page: 'NewPaySplashWithProduct', _pageId: 'D04' };
    default:
      return { _page: schema._page, _pageId: schema._pageId };
  }
};

export const getEligibilityData = () => ({
  eligibilityData: {
    eligibleTermsTitle: 'Subject to final checks, you’re eligible for the following terms:',
    eligibleTermsSubTitle: 'Fixed monthly instalment plans of',
    maxPromoOffers: 0,
    offerTitle: 'Instalment Offer (Terms Apply)',
    aprLabel: 'Variable',
    creditLimitLabel: 'Estimated credit limit',
    creditInfo: {
      assumedCreditLimit: parseInt(Cookies.get(CookieType.EligibilityCheckLimit), 10),
      apr: parseFloat(Cookies.get(CookieType.EligibilityCheckApr)),
    },
  },
});

export const createSchemaWithProduct = (schema: SchemaObject, productAmount: number): SchemaObject => {
  const isEligible = Cookies.get(CookieType.EligibilityCheckStatus) === 'true';
  const updatedSchema = { ...schema };
  const eligibilityData = getEligibilityData();

  if (isEligible && updatedSchema.meta) {
    delete updatedSchema.meta.repExamples;
  }

  return {
    ...updatedSchema,
    ...getPromotionalPageNameFromSchema(updatedSchema),
    meta: {
      ...updatedSchema.meta,
      productAmount,
      isShownWIQButton: AppPropManager.isShownWIQButton,
      offers: createInstalments(productAmount),
      ...(isEligible && eligibilityData),
    },
  };
};

type OnLoadMessage = {
  showCookieConsent: boolean;
  showCookieNoticeBanner: boolean;
  jsonSchema: SchemaObject;
  env: EnvironmentVariableContextInterface;
  cookieStatus: string;
  origin: string;
};

const createOnLoadMessage = (
  schema: SchemaObject,
  environmentVariables: EnvironmentVariableContextInterface
): OnLoadMessage => ({
  jsonSchema: schema,
  env: environmentVariables,
  cookieStatus: localStorage.getItem(LocalStorageType.CookieStatus),
  origin: window.location.origin,
  ...handleCookieUIs(localStorage.getItem(LocalStorageType.CurrentProductName) as ProductNames, schema.lender),
});

const createOnLoadPayload = (message: OnLoadMessage): OnEventIFramePayload => ({
  type: Commands.ALL,
  data: Buffer.from(JSON.stringify(message)).toString('base64'),
});

export function onLoadIFrame({
  iFrame,
  schema,
  environmentVariables,
  originUrl,
}: OnLoadIFrameParams): IFrameRemoveEventListener {
  const onLoad = () => {
    const message = createOnLoadMessage(schema, environmentVariables);
    const payload = createOnLoadPayload(message);

    iFrame.contentWindow.postMessage(payload, originUrl);
  };

  iFrame.addEventListener('load', onLoad);

  return () => iFrame.removeEventListener('load', onLoad);
}

type OnMessageIFrameParams = { iFrame: HTMLIFrameElement; originUrl: string };

const createOnMessagePayload = (originUrl: string): OnEventIFramePayload => ({
  type: Commands.OFFERS,
  data: encodeBase64({ offers: AppPropManager.offers, origin: originUrl }),
});

export function onMessageIFrame({ iFrame, originUrl }: OnMessageIFrameParams): IFrameRemoveEventListener {
  const subscription = (m: MessageEvent<{ message?: MessageCommands }>) => {
    if (m.data?.message === MessageCommands.FRAME_READY) {
      const payload = createOnMessagePayload(originUrl);
      iFrame.contentWindow.postMessage(payload, originUrl);
    }
  };

  window.addEventListener('message', subscription);
  iFrame.addEventListener('message', subscription);

  return () => {
    window.removeEventListener('message', subscription);
    iFrame.removeEventListener('message', subscription);
  };
}
